import React, { useContext, useEffect } from 'react';
import { LeftGradientReq, HomeContainer, RightGradientRq } from './styled';
import HowItWorks from 'components/HowItWorks';
import CustomersReviews from 'components/CustomersReviews';
import { AppContext } from 'containers/App/context';
import { useHistory } from 'react-router';

const Home = () => {
  const { showSearchDetails, userWebPage } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (showSearchDetails) {
      history.push('/search-details');
    }
  }, [showSearchDetails, history]);

  return (
    <HomeContainer>
      <LeftGradientReq fill={userWebPage.leftGradientFill} />
      <RightGradientRq fill={userWebPage.rightGradientFill} />
      <HowItWorks />
      <CustomersReviews />
    </HomeContainer>
  );
};
export default Home;
