import React, { useContext, useEffect, useState } from 'react';
import { SearchDetailsContainer } from './styled';
import { LeftGradientReq, RightGradientRq } from 'containers/Home/styled';
import { AppContext } from 'containers/App/context';
import { Container, Header, Grid, Dropdown } from 'semantic-ui-react';
import { useHistory } from 'react-router';
import { get } from 'lodash';
import { SearchService, DiscountCardItem } from 'services/SearchService';
import DiscountCardList from 'components/DiscountCardList';
import GetCardModal from 'components/GetCardModal';
import { normalizeOption } from 'shared/utils';

const SearchDetails = () => {
  const { medicationDetails, searchedMedicationName, searchedZipCode, userWebPage } = useContext(
    AppContext,
  );
  const [availableDrugForms, setAvailableDrugForms] = useState<any[]>([]);
  const [availableDosages, setAvailableDosages] = useState<any[]>([]);
  const [quantityOptions, setQuantityOptions] = useState<any[]>([]);
  const [selectedDrugForm, setSelectedDrugForm] = useState<any>({});
  const [selectedDosage, setSelectedDosage] = useState<any>({});
  const [selectedDosageTitle, setSelectedDosageTitle] = useState<any>('');
  const [selectedQuantity, setSelectedQuantity] = useState<any>({});
  const [discountCardList, setDiscountCardList] = useState<DiscountCardItem[]>([]);
  const [selectedDiscountCard, setSelectedDiscountCard] = useState<DiscountCardItem>();
  const [showGetCardModal, setShowGetCardModal] = useState(false);
  const [rxGroup, setRxGroup] = useState('');
  const [rxBIN, setRxBIN] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (!medicationDetails.MedicationID) {
      history.replace('/');
    }
  }, [history, medicationDetails.MedicationID]);

  useEffect(() => {
    const getDiscountCardDetails = async () => {
      const {
        data: { result },
      } = await SearchService.GetDiscountCardDetail({
        ndc: selectedQuantity.NDC,
        zip: searchedZipCode,
        qtt: selectedQuantity.QuantityFloat,
      });
      setDiscountCardList(result[0].DiscountCard);
      setRxGroup(result[0].RxGroup);
      setRxBIN(result[0].RxBIN);
    };
    if (selectedQuantity && selectedQuantity.NDC) {
      getDiscountCardDetails();
    }
  }, [selectedQuantity, searchedZipCode]);

  useEffect(() => {
    const initDrugForms = () => {
      const { DrugForms } = medicationDetails;
      if (DrugForms) {
        setAvailableDrugForms(
          DrugForms.map((drug: any) => normalizeOption(drug.Form, drug.Data[0].Dosages)),
        );
        const firstElement: any = DrugForms[0];
        setSelectedDrugForm(get(firstElement, 'Data[0].Dosages'));

        setAvailableDosages(
          get(firstElement, 'Data[0].Dosages').map((dos: any) =>
            normalizeOption(dos.Dosage, dos.Data),
          ),
        );
        setSelectedDosage(get(firstElement, 'Data[0].Dosages[0].Data'));
        setSelectedDosageTitle(get(firstElement, 'Data[0].Dosages[0].Dosage'));
        setQuantityOptions(
          get(firstElement, 'Data[0].Dosages[0].Data').map((quantity: any) =>
            normalizeOption(quantity.DisplayQuantity, quantity),
          ),
        );
        setSelectedQuantity(get(firstElement, 'Data[0].Dosages[0].Data[0]'));
      }
    };

    initDrugForms();
  }, [medicationDetails]);

  const onDrugFormChanged = (_: any, selection: any) => {
    const { value } = selection;
    setSelectedDosageTitle(value[0].Dosage);
    const firstElement = value[0].Data;
    setSelectedDrugForm(value);
    setAvailableDosages(value.map((dos: any) => normalizeOption(dos.Dosage, dos.Data)));
    setQuantityOptions(
      firstElement.map((quantity: any) => normalizeOption(quantity.DisplayQuantity, quantity)),
    );
    setSelectedDosage(firstElement);
    setSelectedQuantity(firstElement[0]);
  };

  const onDosagesChanged = (_: any, selection: any) => {
    const { value, options } = selection;
    setSelectedDosageTitle(options.filter((v: any) => v.value === value)[0].text);
    setSelectedDosage(value);
    setQuantityOptions(
      value.map((quantity: any) => normalizeOption(quantity.DisplayQuantity, quantity)),
    );
    setSelectedQuantity(value[0]);
  };

  const onQuantityChanged = (_: any, selection: any) => {
    const { value } = selection;
    setSelectedQuantity(value);
  };

  const onShowGetCard = (item: DiscountCardItem) => {
    setSelectedDiscountCard(item);
    setShowGetCardModal(true);
  };

  const medicationFullName = `${searchedMedicationName} ${selectedDosageTitle}, ${selectedQuantity.DisplayQuantity}`;

  return (
    <SearchDetailsContainer>
      <GetCardModal
        userWebPage={userWebPage}
        medicationFullName={medicationFullName}
        selectedCard={selectedDiscountCard}
        rxBIN={rxBIN}
        rxGroup={rxGroup}
        show={showGetCardModal}
        onClose={() => setShowGetCardModal(false)}
      />
      <LeftGradientReq fill={userWebPage.leftGradientFill} />
      <RightGradientRq fill={userWebPage.rightGradientFill} />
      <Container>
        <Header as='h1'>{medicationFullName}</Header>
        <Header as='h5' style={{ opacity: 1 }}>
          Customize the dosage and quantity:
        </Header>
        <Grid className='filter-grid'>
          <Grid.Row columns={3}>
            <Grid.Column mobile={16} computer={4}>
              {availableDrugForms && availableDrugForms.length > 0 && (
                <Dropdown
                  fluid
                  onChange={onDrugFormChanged}
                  selection
                  options={availableDrugForms}
                  value={selectedDrugForm}
                />
              )}
            </Grid.Column>
            <Grid.Column mobile={16} computer={4}>
              {availableDosages && availableDosages.length > 0 && (
                <Dropdown
                  fluid
                  onChange={onDosagesChanged}
                  selection
                  options={availableDosages}
                  value={selectedDosage}
                />
              )}
            </Grid.Column>
            <Grid.Column mobile={16} computer={4}>
              {quantityOptions && quantityOptions.length > 0 && (
                <Dropdown
                  fluid
                  onChange={onQuantityChanged}
                  selection
                  options={quantityOptions}
                  value={selectedQuantity}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <DiscountCardList
          userWebPage={userWebPage}
          onShowGetCard={onShowGetCard}
          discountCardList={discountCardList}
        />
      </Container>
    </SearchDetailsContainer>
  );
};

export default SearchDetails;
