import RxValetAPIService from './RxValetAPIService';

export type SearchMedicationType = {
  s: string;
};

export type GetMedicationDetailsType = {
  mid: string;
  zip: string;
};

export type GetDiscountCardDetailsType = {
  ndc: string;
  qtt: number;
  zip: string;
};

export type DiscountCardItem = {
  PharmacyName: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  Phone: string;
  Price: string;
  NCPDP: string;
};

export type SendEmailTextRequestBody = {
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  State: string;
  DrugName: string;
  PharmacyName: string;
  Address: string;
  ContactNumber: string;
  Price: string;
};

export class SearchService {
  static async SearchMedication(payload: SearchMedicationType): Promise<any> {
    return await RxValetAPIService.getData('/searchMedication', payload);
  }
  static async GetMedicationDetails(payload: GetMedicationDetailsType): Promise<any> {
    return await RxValetAPIService.getData('/medicationDetails', payload);
  }
  static async GetDiscountCardDetail(payload: GetDiscountCardDetailsType): Promise<any> {
    return await RxValetAPIService.getData('/discountCardDetails', payload);
  }
  static async SendEmailText(payload: SendEmailTextRequestBody, isText = false): Promise<any> {
    return await RxValetAPIService.postData(
      isText ? '/sendEmailOrText?text=true' : '/sendEmailOrText',
      payload,
    );
  }
}
