/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect, SyntheticEvent, useContext } from 'react';
import Rodal from 'rodal';
import {
  Form,
  Button,
  Transition,
  Header,
  Grid,
  InputOnChangeData,
  DropdownProps,
  Responsive,
} from 'semantic-ui-react';
import { ToastContainer, toast } from 'react-toastify';
import { ModalContainer } from './styled';
import { DiscountCardItem, SearchService } from 'services/SearchService';
import { useReactToPrint } from 'react-to-print';
import ComponentToPrint from './ComponentToPrint';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX, USA_STATES_LIST } from 'shared/constants';
import { normalizeOption } from 'shared/utils';
import { AppContext, UserWebPageType } from 'containers/App/context';
import { SavingCardTemplate } from 'shared/icons';

export type UserFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  state: string;
};

type GetCardModalTypes = {
  rxGroup: string;
  rxBIN: string;
  selectedCard?: DiscountCardItem;
  show: boolean;
  onClose: () => void;
  medicationFullName: string;
  userWebPage: UserWebPageType;
};

const options = USA_STATES_LIST.map(({ name }: { name: string }) => normalizeOption(name, name));

export const DisclaimerContent = () => (
  <>
    <Header as='h3'>This is not insurance</Header>
    <p>
      Discounts are available exclusively through participating pharmacies. The range of the
      discounts will vary depending on the prescription and the pharmacy chosen. This program does
      not make payments directly to pharmacies. Members are required to pay for all prescription
      purchases. You may cancel your registration at any time or file a complaint by contacting
      Customer Care. This program is administered by Medical Security Card Company,LLC of Tucson,
      AZ.
    </p>
    <Header as='h4'>Pharmacy Help Desk (800) 404-1031</Header>
  </>
);

const GetCardModal = ({
  show,
  onClose,
  rxGroup,
  rxBIN,
  selectedCard,
  medicationFullName,
  userWebPage,
}: GetCardModalTypes) => {
  const { searchedMedicationName } = useContext(AppContext);
  const [showGetCardOptions, setShowGetCardOptions] = useState(false);
  const [sendingDiscountCard, setSendingDiscountCard] = useState({ loading: false, isText: false });
  const { register, setValue, handleSubmit, errors, triggerValidation } = useForm<UserFormData>();
  const [userData, setUserData] = useState<UserFormData>();
  const hasErrors = Object.keys(errors).length > 0;
  const toPrintComponentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => toPrintComponentRef.current,
  });

  useEffect(() => {
    register({ name: 'firstName' }, { required: true });
    register({ name: 'lastName' }, { required: true });
    register({ name: 'state' }, { required: true });
    register(
      { name: 'email' },
      {
        required: true,
        pattern: EMAIL_REGEX,
      },
    );
    register({ name: 'phone' }, { required: true });
  }, [register]);

  const onSubmit = handleSubmit((userData: UserFormData) => {
    setUserData(userData);
    setShowGetCardOptions(true);
  });

  const onModalClose = () => {
    setShowGetCardOptions(false);
    onClose();
  };

  const onFormValueChanged = async (_: SyntheticEvent, { name, value }: InputOnChangeData) => {
    setValue(name, value, true);
    await triggerValidation(name);
  };

  const onStateChanged = async (_event: SyntheticEvent, { value, name }: DropdownProps) => {
    setValue(name, value, true);
    await triggerValidation(name);
  };

  const onSendDiscountCard = async (isText = false) => {
    setSendingDiscountCard({ isText, loading: true });
    const {
      data: { statusCode },
    } = await SearchService.SendEmailText(
      {
        FirstName: userData?.firstName || '',
        LastName: userData?.lastName || '',
        State: userData?.state || '',
        Email: userData?.email || '',
        PhoneNumber: userData?.phone || '',
        Address: selectedCard?.Address || '',
        ContactNumber: selectedCard?.Phone || '',
        PharmacyName: selectedCard?.PharmacyName || '',
        Price: selectedCard?.Price || '0',
        DrugName: searchedMedicationName,
      },
      isText,
    );
    setSendingDiscountCard({ isText, loading: false });
    switch (statusCode) {
      case '0':
        toast.error('Error sending the Discount card');
        break;
      case '1':
        toast.success('Discount card sent');
        break;
      default:
        break;
    }
  };

  const getActionButtons = () => {
    return (
      <Grid>
        <Grid.Row centered columns={3}>
          <Grid.Column mobile={16} tablet={16} computer={5}>
            <Button
              loading={sendingDiscountCard.loading && sendingDiscountCard.isText}
              onClick={() => onSendDiscountCard(true)}
              fluid
              style={{ backgroundColor: userWebPage.actionButtonColor }}
              color='orange'
            >
              Text
            </Button>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={5}>
            <Button
              style={{ backgroundColor: userWebPage.actionButtonColor }}
              loading={sendingDiscountCard.loading && !sendingDiscountCard.isText}
              onClick={() => onSendDiscountCard(false)}
              fluid
              color='orange'
            >
              Email
            </Button>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={5}>
            <Button
              style={{ backgroundColor: userWebPage.actionButtonColor }}
              basic
              onClick={handlePrint}
              color='orange'
              fluid
            >
              Print
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const getCardTemplate = () => {
    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <SavingCardTemplate savingsCardStyles={userWebPage.savingsCard} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            {DisclaimerContent()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <ModalContainer>
      <Rodal visible={show} onClose={onModalClose}>
        <Transition visible={!showGetCardOptions} animation='slide down' duration={500}>
          <div className='formContainer'>
            <Header as='h2'>Get card</Header>
            <Form onSubmit={onSubmit}>
              <Form.Input
                error={errors.firstName != null}
                onChange={onFormValueChanged}
                name='firstName'
                placeholder='First name'
              />
              <Form.Input
                error={errors.lastName != null}
                onChange={onFormValueChanged}
                name='lastName'
                placeholder='Last name'
              />
              <Form.Input
                error={errors.email != null}
                onChange={onFormValueChanged}
                name='email'
                type='email'
                placeholder='Email'
              />
              <Form.Input
                error={errors.phone != null}
                onChange={onFormValueChanged}
                name='phone'
                maxLength={10}
                type='tel'
                placeholder='Phone'
              />
              <Form.Select
                error={errors.state != null}
                name='state'
                onChange={onStateChanged}
                options={options}
                placeholder='State'
              />
              {hasErrors && <p>Please check that the fields are entered correctly.</p>}
              <Button
                style={{ backgroundColor: userWebPage.actionButtonColor }}
                fluid
                type='submit'
              >
                Get Your Card
              </Button>
            </Form>
          </div>
        </Transition>
        <Transition visible={showGetCardOptions} animation='slide up' duration={500}>
          <div className='getYourCardContainer'>
            <Header as='h2'>Get your card</Header>
            <ComponentToPrint
              savingsCardStyles={userWebPage.savingsCard}
              ref={toPrintComponentRef}
              selectedCard={selectedCard}
              rxBIN={rxBIN}
              rxGroup={rxGroup}
              medicationName={medicationFullName}
              userData={userData}
            />
            <Responsive {...Responsive.onlyMobile}>
              {getActionButtons()}
              {getCardTemplate()}
            </Responsive>
            <Responsive {...Responsive.onlyTablet}>
              {getActionButtons()}
              {getCardTemplate()}
            </Responsive>
            <Responsive {...Responsive.onlyComputer}>
              {getCardTemplate()}
              {getActionButtons()}
            </Responsive>
          </div>
        </Transition>
      </Rodal>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </ModalContainer>
  );
};

export default GetCardModal;
