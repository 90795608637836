import styled from 'styled-components';
import { colors } from 'shared/theme';

export const HeaderContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: calc(100vw - 10%);
  right: 0;
  left: 0;
  margin: 0 auto;

  .rodal-dialog {
    width: 300px !important;
    height: 135px !important;
    background: #ffffff;
    box-shadow: 0px 20px 40px rgba(26, 58, 97, 0.06);
    border-radius: 10px;
    padding: 50px 25px;

    .sms-action {
      vertical-align: top;
      margin: 2px 3px;
    }
  }
  .ui.menu {
    border: none;
    box-shadow: none;
    height: 100px;
    background: transparent;

    .item > img.ui.image {
      width: 200px;
      margin-top: 33px;
    }
    .ui.basic.button {
      box-shadow: none;
      border: none;
      color: ${colors.basicText};
      font-size: 16px !important;
    }
    .sign-in-btn {
      color: ${colors.mainOrange} !important;
    }
  }

  @media (max-width: 767px) {
    .ui.menu {
      height: 60px;
      .item > img.ui.image {
        width: 133.55px;
      }
    }
  }
`;
