import styled from 'styled-components';

export const HomeContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  padding-top: 600px;
  @media (max-width: 767px) {
    padding-top: 384px;
  }
`;

export const LeftGradientReq = styled.div<{ fill: string }>`
  border-bottom-right-radius: 150px;
  position: absolute;
  width: 80%;
  height: 654px;
  left: 0px;
  top: 0px;

  @media (max-width: 767px) {
    width: 100%;
    height: 580px;
  }

  background: linear-gradient(
      111.82deg,
      rgba(255, 255, 255, 0) 9.54%,
      rgba(255, 255, 255, 0.8) 98.71%
    ),
    linear-gradient(0deg, #ffffff, #f4f0fd), #431599;
`;

export const RightGradientRq = styled.div<{ fill: string }>`
  border-bottom-right-radius: 150px;
  position: absolute;
  width: 650px;
  height: 650px;
  right: -50px;
  top: 280px;

  background: linear-gradient(165.01deg, rgba(255, 255, 255, 0) 16.45%, rgb(225 215 243) 89.44%);

  transform: rotate(-180deg);

  @media (max-width: 767px) {
    width: 200px;
    height: 332px;
    right: 0;
  }
`;
