import React from 'react';
import { CustomersReviewsContainer, BgGradientReq } from './styled';
import { Header, Grid, Image } from 'semantic-ui-react';
import QuoteIcon from 'assets/icons/quote.svg';
import DemoProfile from 'assets/icons/demoProfile.svg';

const CustomersReviews = () => {
  return (
    <CustomersReviewsContainer>
      <Header as='h2'>What our customers are saying</Header>
      <BgGradientReq />
      <Grid>
        <Grid.Row columns={2} textAlign='left' verticalAlign='middle'>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Image src={DemoProfile} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Header as='h3' image>
              <Image src={QuoteIcon} size='large' />
              <br />
              Mary Swenson
              <Header.Subheader>
                I recently lost my job and with it my prescription coverage. As a diabetic patient
                taking 5 medications every day it has become nearly impossible for me to budget and
                afford the $400+ every month for my meds. The Pillio.co discount card has helped me
                save $100&apos;s and for the first time in months I can afford to fill all of my
                prescriptions. Thank you Pillio.co!!!
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CustomersReviewsContainer>
  );
};

export default CustomersReviews;
