import styled from 'styled-components';
import { colors } from 'shared/theme';

export const SearchDetailsContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  padding-top: 300px;
  .ui.container {
    .ui.fluid.dropdown {
      margin: 5px 0;
      border: none;
      box-shadow: 0px 1px 3px 0px rgb(67 21 153 / 50%);
    }
    position: relative;
    width: calc(100vw - 10%);
    h1 {
      font-family: Ubuntu;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 37px;
      color: ${colors.basicText};
      letter-spacing: -0.01em;
    }
    h5 {
      font-family: Ubuntu;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      opacity: 0.5;
      color: ${colors.basicText};
    }
  }

  .filter-grid {
    max-width: 800px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: ${colors.basicText};
    i.dropdown.icon {
      color: ${colors.mainOrange};
    }
  }

  @media (max-width: 767px) {
    padding-top: 313px;
    .ui.container {
      .ui.fluid.dropdown {
        margin: 5px 0;
      }
      h1 {
        font-size: 25px;
        line-height: 34px;
      }
    }

    .filter-grid {
      font-size: 12px;
    }
  }
`;
