import styled from 'styled-components';
import { colors } from 'shared/theme';

export const CustomersReviewsContainer = styled.div`
  position: relative;
  display: block;

  h2.ui.header {
    padding-top: 30px;
    text-align: center;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: -0.01em;
    color: ${colors.basicText};
  }
  h3.ui.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: ${colors.basicText};
    margin-bottom: 15px;
  }
  h3.ui.header .sub.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-top: 15px;
    max-width: 450px;
  }
  .ui.header > .image:not(.icon),
  .ui.header > img {
    margin-bottom: 30px;
  }
  .ui.grid {
    margin: 50px 150px;
  }

  @media (max-width: 767px) {
    .ui.grid {
      margin: 0;
    }
    .ui.header > .image:not(.icon),
    .ui.header > img {
      height: 64px !important;
      width: 64px !important;
    }
  }
`;
export const BgGradientReq = styled.div`
  border-bottom-right-radius: 150px;
  position: absolute;
  width: 80%;
  height: 760px;
  z-index: -1;
  right: -170px;
  top: -37px;

  @media (max-width: 767px) {
    width: 100vw;
    height: 830px;
    right: -5%;
    top: -20px;
  }

  background: linear-gradient(165.01deg, rgba(255, 255, 255, 0) 16.45%, rgb(225 215 243) 89.44%);
  transform: rotate(-180deg);
`;
