import styled from 'styled-components';
import { colors } from 'shared/theme';

export const SearchBarContainer = styled.div<{ showSearchDetails: boolean }>`
  z-index: 999;
  .icon-input {
    img.icon {
      left: 0;
      position: absolute;
      margin: 9px 10px;
    }
  }

  .ui.search > .results {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
  }

  h1.ui.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 57px;
    letter-spacing: -0.01em;
    color: ${colors.basicText};
    margin: 60px 0;
    ${(props) =>
      props.showSearchDetails &&
      `
       height: 0px;
       margin: 0;
       font-size: 0px;
    `}
    transition: height, margin .5s;
  }
  .ui.grid {
    width: 100%;
    height: 100px;
    left: 165px;
    background: #ffffff;
    box-shadow: 0px 20px 40px rgba(26, 58, 97, 0.06);
    border-radius: 10px;
    margin: 0 auto;
  }
  .ui.search .prompt {
    border-radius: 5px;
    border: none;
  }

  .ui.grid .column > .input input {
    border: none;
  }

  .ui.orange.button,
  .ui.orange.buttons .button {
    color: #fff;
    text-shadow: none;
    width: 100%;
    height: 70px;
    background: ${colors.mainOrange};
    border-radius: 6px;
  }

  .ui.divider {
    display: none;
  }
  .ui.input {
    width: 100%;
  }

  /* Media queries */

  @media (max-width: 990px) {
    .ui.grid {
      height: 170px;
    }
  }
  @media (max-width: 767px) {
    .ui.orange.button,
    .ui.orange.buttons .button {
      height: 50px;
    }

    .ui.grid {
      height: 203px;
    }
    .ui.divider {
      display: block;
      margin: 0;
      margin-top: 10px;
    }
    .zip-column {
      padding-top: 0 !important;
    }
    h1.ui.header {
      font-size: 28px;
      line-height: 38px;
      height: auto;
      margin: 10px 0;
      margin-top: 0;
      ${(props) =>
        props.showSearchDetails &&
        `
       height: 0px;
       margin: 0;
       font-size: 0px;
    `}
    }
  }
`;
