import React from 'react';
import { DiscountCardItem } from 'services/SearchService';
import { DiscountCardListContainer } from './styled';
import { List, Grid, Header, Button, Image } from 'semantic-ui-react';
import NoteIcon from 'assets/icons/note.svg';
import { UserWebPageType } from 'containers/App/context';

type DiscountCardListTypes = {
  onShowGetCard: (item: DiscountCardItem) => void;
  discountCardList: DiscountCardItem[];
  userWebPage: UserWebPageType;
};

const DiscountCardList = ({
  discountCardList,
  onShowGetCard,
  userWebPage,
}: DiscountCardListTypes) => {
  return (
    <DiscountCardListContainer>
      <List>
        {discountCardList.map((card: DiscountCardItem) => (
          <List.Item key={card.NCPDP}>
            <Grid columns='equal'>
              <Grid.Column mobile={16} tablet={8} computer={4}>
                <Header as='h2'>{card.PharmacyName}</Header>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={4}>
                <Header as='h5'>{`${card.Address}, ${card.City}, ${card.State}`}</Header>
                <Header as='h5'>{card.Zip}</Header>
                <Header as='h5' style={{ opacity: 1 }}>
                  {card.Phone}
                </Header>
              </Grid.Column>
              <Grid.Column textAlign='right' mobile={16} tablet={8} computer={4}>
                <Header as='h1'>$ {card.Price}</Header>
                <span>with prescription savings card</span>
              </Grid.Column>
              <Grid.Column textAlign='right' mobile={16} tablet={8} computer={4}>
                <Button
                  style={{ backgroundColor: userWebPage.actionButtonColor }}
                  onClick={() => onShowGetCard(card)}
                  color='orange'
                >
                  Get Card
                </Button>
              </Grid.Column>
            </Grid>
          </List.Item>
        ))}
      </List>
      {discountCardList.length > 0 && (
        <>
          <Header as='h3' image>
            <Image src={NoteIcon} size='small' /> Note
          </Header>
          <Header as='h6'>
            Prescription prices may vary from pharmacy to pharmacy and are subject to change. The
            pricing estimates given are based on the most recent information available and may
            change based on when you actually fill your prescription at the pharmacy.
            <br />
            <br />
            The day supply is based upon the average dispensing patterns or the specific drug and
            strength. The Program, as well as the prices and the list of covered drugs, can be
            modified at any time without notice.
          </Header>
        </>
      )}
    </DiscountCardListContainer>
  );
};

export default DiscountCardList;
