import React, { useState, ReactChild, useEffect } from 'react';
import AddToHome from 'shared/AddToHome';
import { isMobileDevice, getLastVisit, saveLastVisit } from 'shared/utils';
import PillioW from 'assets/logos/pillioW.png';
import NoContent from 'components/NoContent';
export interface UserWebPageType {
  userId?: string;
  userWebPageId?: string;
  webLogo: any;
  webLogoURL?: any;
  title1: string;
  title2: string;
  leftGradientFill: string;
  rightGradientFill: string;
  strongTextColor: string;
  actionButtonColor: string;
  companyName: string;
  subDomainName: string;
  createdAt?: number;
  savingsCard: PrescriptionCardType;
}

export interface PrescriptionCardType {
  cardLogo: any;
  cardLogoURL?: any;
  savingCardTitle: string;
  backgroundColor: string;
  secundaryColor: string;
  strongTextColor: string;
  lightTextColor: string;
}

export const initialWebValues: UserWebPageType = {
  webLogo: '',
  webLogoURL: null,
  title1: 'Shop Prices',
  title2: 'Save On Meds!',
  leftGradientFill: '#430199',
  rightGradientFill: '#43019945',
  strongTextColor: '#1a3a61',
  actionButtonColor: '#430199',
  companyName: 'Pillio.co',
  subDomainName: '',
  savingsCard: {
    backgroundColor: '#430199',
    cardLogo: '',
    cardLogoURL: PillioW,
    lightTextColor: '#97A6B8',
    savingCardTitle: 'Pillio.co',
    secundaryColor: '#430199',
    strongTextColor: '#FFFFFF',
  },
};
const AppContext: any = React.createContext({});

type Props = {
  children: ReactChild;
};

const AppProvider = ({ children }: Props) => {
  const [searchedMedicationID, setSearchedMedicationID] = useState();
  const [searchedMedicationName, setSearchedMedicationName] = useState();
  const [searchedZipCode, setSearchedZipCode] = useState();
  const [showSearchDetails, setShowSearchDetails] = useState();
  const [medicationDetails, setMedicationDetails] = useState({});
  const [showAddToHome, setShowAddToHome] = useState(false);
  const [configLoaded] = useState(true);
  const [userWebPage] = useState<UserWebPageType>(initialWebValues);

  const addToHomeCanBeShowed =
    isMobileDevice.iOS() && !window.matchMedia('(display-mode: standalone)').matches;

  const showAddToHomeMessage = () => {
    setTimeout(() => {
      setShowAddToHome(true);
    }, 5000);
  };

  useEffect(() => {
    const checkLastVisit = () => {
      const lastVisitTimespan = getLastVisit();
      if (!lastVisitTimespan) {
        if (addToHomeCanBeShowed) {
          showAddToHomeMessage();
        }
      } else {
        const currentDate = new Date(Date.now());
        const lastVisitDate = new Date(lastVisitTimespan);
        // This allow us to show the Messages if the last visit was more than 2 days ago.
        const validDifference = currentDate.getDate() - lastVisitDate.getDate() >= 2;
        if (validDifference) {
          if (addToHomeCanBeShowed) {
            showAddToHomeMessage();
          }
        }
      }
      saveLastVisit(Date.now());
    };
    checkLastVisit();
  }, [addToHomeCanBeShowed]);

  return (
    <AppContext.Provider
      value={{
        userWebPage,
        searchedMedicationID,
        setSearchedMedicationID,
        searchedZipCode,
        setSearchedZipCode,
        showSearchDetails,
        setShowSearchDetails,
        medicationDetails,
        setMedicationDetails,
        searchedMedicationName,
        setSearchedMedicationName,
      }}
    >
      {configLoaded ? (
        <>
          <AddToHome onClose={() => setShowAddToHome(false)} show={showAddToHome} />
          {children}
        </>
      ) : (
        <NoContent message='Loading...' isLoading={true} />
      )}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
