import React from 'react';
import { DiscountCardItem } from 'services/SearchService';
import { Grid, Header } from 'semantic-ui-react';
import { UserFormData, DisclaimerContent } from '.';
import { SavingCardTemplate } from 'shared/icons';
import { PrescriptionCardType } from 'containers/App/context';

interface ComponentToPrintProps {
  rxGroup: string;
  rxBIN: string;
  selectedCard?: DiscountCardItem;
  medicationName: string;
  userData?: UserFormData;
  savingsCardStyles: PrescriptionCardType;
}

class ComponentToPrint extends React.Component<ComponentToPrintProps> {
  render() {
    const {
      rxBIN,
      rxGroup,
      selectedCard,
      medicationName,
      userData,
      savingsCardStyles,
    } = this.props;
    const currentDate = new Date();
    return (
      <Grid className='print-section' style={{ display: 'none' }}>
        <Grid.Row columns={2}>
          <Grid.Column className='print-card-container' mobile={16} tablet={8} computer={8}>
            <SavingCardTemplate
              savingsCardStyles={savingsCardStyles}
              memberName={`${userData?.firstName} ${userData?.lastName}`}
              GRP={rxGroup}
              PCN={rxBIN}
              BIN={'012345'}
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            {DisclaimerContent()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='print-pharmacy-details' columns={1}>
          <Grid.Column>
            <Header as='h2'>Pharmacy Details</Header>
            <p>
              Estimated price for your selected medications as of {currentDate.toLocaleDateString()}
            </p>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column textAlign='right'>
                  <Header as='h3'>Drug Name :</Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h3' style={{ opacity: 0.5 }}>
                    {medicationName}
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column textAlign='right'>
                  <Header as='h3'>Pharmacy Name :</Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h3' style={{ opacity: 0.5 }}>
                    {selectedCard?.PharmacyName}
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column textAlign='right'>
                  <Header as='h3'>Address :</Header>
                </Grid.Column>
                <Grid.Column>
                  <Header
                    as='h3'
                    style={{ opacity: 0.5 }}
                  >{`${selectedCard?.Address}, ${selectedCard?.City}, ${selectedCard?.State}`}</Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column textAlign='right'>
                  <Header as='h3'>Contact Number :</Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h3' style={{ opacity: 0.5 }}>
                    {selectedCard?.Phone}
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column textAlign='right'>
                  <Header as='h3'>Price :</Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h3' style={{ opacity: 0.5 }}>
                    $ {selectedCard?.Price}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  defaultProps = {
    rxGroup: '',
    rxBIN: '',
  };
}

export default ComponentToPrint;
