import React, { useContext } from 'react';
import { FooterContainer } from './styled';
import { Menu, Header, Image, Grid } from 'semantic-ui-react';
import EHNIsoTrans from 'assets/logos/ehnisoTrans.png';
import { AppContext } from 'containers/App/context';
import { PillsIcon, PhoneIcon } from 'shared/icons';

const Footer = () => {
  const { userWebPage } = useContext(AppContext);

  return (
    <FooterContainer>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Menu stackable borderless position='left'>
              <Menu.Item>
                <Grid columns={1} textAlign='left'>
                  <Grid.Row verticalAlign='middle'>
                    <Grid.Column>
                      <Header as='h3' image>
                        <Image className='logo' src={userWebPage.webLogoURL || EHNIsoTrans} />
                        <br />
                        With the {userWebPage.companyName} Prescription Savings Card you can save up
                        to 80% on prescription drugs! Once you have your card you can use it for
                        your entire family as often as you like!
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Menu.Item>

              <Menu.Menu stackable borderless position='right'>
                <Grid textAlign='left'>
                  <Grid.Row columns={2} verticalAlign='middle'>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <Header as='h3' image>
                        <Grid textAlign='left'>
                          <Grid.Row columns={3}>
                            <Grid.Column mobile={4} computer={16}>
                              <PillsIcon webPageStyles={userWebPage} />
                            </Grid.Column>
                            <Grid.Column mobile={8} computer={16}>
                              Pharmacy Help Desk <br />
                              <Header.Subheader>800-404-1031</Header.Subheader>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Header>
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <Header as='h3' image>
                        <Grid textAlign='left'>
                          <Grid.Row columns={3}>
                            <Grid.Column mobile={4} computer={16}>
                              <PhoneIcon webPageStyles={userWebPage} />
                            </Grid.Column>
                            <Grid.Column mobile={8} computer={16}>
                              Prescription Savings Card Customer Care <br />
                              <Header.Subheader>855-798-2538</Header.Subheader>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Menu.Menu>
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} verticalAlign='middle'>
          <Grid.Column>
            <Header as='h3' className='copyright'>
              <Grid textAlign='center'>
                <Grid.Row columns={2} verticalAlign='middle'>
                  <Grid.Column mobile={16} computer={8}>
                    <Header.Subheader>Terms of Use & Privacy Policy</Header.Subheader>
                  </Grid.Column>
                  <Grid.Column className='copyright-p' mobile={16} computer={8}>
                    © 2020 {userWebPage.companyName} | All Rights Reserved
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FooterContainer>
  );
};
export default Footer;
